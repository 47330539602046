import init from '@/lib/_init'

const branch =
  process.env.NEXT_PUBLIC_USER_BRANCH ||
  process.env.AWS_BRANCH ||
  process.env.CIRCLE_BRANCH

const envObj = {
  API_URL: process.env.API_URL || process.env.NEXT_PUBLIC_API_URL || '',
  APP_URL:
    process.env.APP_URL ||
    process.env.NEXT_PUBLIC_APP_URL ||
    'http://localhost:8081',
  PLATFORM_URL:
    process.env.PLATFORM_URL ||
    process.env.NEXT_PUBLIC_PLATFORM_URL ||
    'https://app.consentwow.com',

  EMAIL_RECIPIENTS:
    process.env.EMAIL_RECIPIENTS ||
    process.env.NEXT_PUBLIC_EMAIL_RECIPIENTS ||
    'support@datawow.io,sales@datawow.io',

  EMAIL_API_URL:
    process.env.EMAIL_API_URL ||
    process.env.NEXT_PUBLIC_EMAIL_API_URL ||
    '',
  SLACK_API_URL:
    process.env.SLACK_API_URL ||
    process.env.NEXT_PUBLIC_SLACK_API_URL ||
    '',
  SLACK_WEBHOOK_URL:
    process.env.SLACK_WEBHOOK_URL ||
    process.env.NEXT_PUBLIC_SLACK_WEBHOOK_URL ||
    '',

  GTM_CONTAINER_ID: '',
  COOKIE_BANNER_ID: '',

  IS_DEV: process.env.NODE_ENV === 'development',
  IS_PROD: false,
}

if (branch === 'production') {
  envObj.GTM_CONTAINER_ID =
    process.env.GTM_CONTAINER_ID ||
    process.env.NEXT_PUBLIC_GTM_CONTAINER_ID ||
    ''
  envObj.COOKIE_BANNER_ID =
    process.env.COOKIE_BANNER_ID ||
    process.env.NEXT_PUBLIC_COOKIE_BANNER_ID ||
    ''
  envObj.IS_PROD = true
}

init(envObj.API_URL)

export default { ...envObj }
